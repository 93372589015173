import React, { Fragment } from "react";

import PropTypes from "prop-types";

import "./gallery15.css";

const Gallery15 = (props) => {
  return (
    <div
      id={props.gallery15Id}
      className="gallery15-gallery3 thq-section-padding"
    >
      <div className="gallery15-max-width thq-section-max-width">
        <div className="gallery15-section-title">
          <h2>
            {props.heading1 ?? (
              <Fragment>
                <h2 className="gallery15-text21 thq-heading-2">
                  Default value
                </h2>
              </Fragment>
            )}
          </h2>
          <span>
            {props.content1 ?? (
              <Fragment>
                <span className="gallery15-text16 thq-body-large">
                  Default value
                </span>
              </Fragment>
            )}
          </span>
        </div>
        <div className="gallery15-content">
          <div className="gallery15-container1">
            <img
              alt={props.image1Alt}
              src={props.image1Src}
              className="gallery15-image1"
            />
            <span>
              {props.image1Title ?? (
                <Fragment>
                  <span className="gallery15-text17 thq-body-small">
                    Default value
                  </span>
                </Fragment>
              )}
            </span>
            <img
              alt={props.image2Alt}
              src={props.image2Src}
              className="gallery15-image2 thq-img-ratio-1-1"
            />
            <span>
              {props.image2Title ?? (
                <Fragment>
                  <span className="gallery15-text18 thq-body-small">
                    Default value
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="gallery15-container2">
            <img
              alt={props.image3Alt}
              src={props.image3Src}
              className="gallery15-image3 thq-img-ratio-1-1"
            />
            <span>
              {props.image3Title ?? (
                <Fragment>
                  <span className="gallery15-text19 thq-body-small">
                    Default value
                  </span>
                </Fragment>
              )}
            </span>
            <img
              alt={props.image4Alt}
              src={props.image4Src}
              className="gallery15-image4 thq-img-ratio-1-1"
            />
            <span>
              {props.image4Title ?? (
                <Fragment>
                  <span className="gallery15-text20 thq-body-small">
                    Image 4
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

Gallery15.defaultProps = {
  content1: undefined,
  image1Title: undefined,
  image2Src:
    "https://images.unsplash.com/photo-1655715590369-90f2ef721607?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNjQwNDI3MXw&ixlib=rb-4.0.3&q=80&w=1080",
  image4Alt: "PlaceholderImage1302",
  image2Title: undefined,
  image3Alt: "Anime Title 3",
  image3Title: undefined,
  image1Src:
    "https://images.unsplash.com/photo-1495434942214-9b525bba74e9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNjQwNDI3MXw&ixlib=rb-4.0.3&q=80&w=1080",
  image4Title: undefined,
  image3Src:
    "https://images.unsplash.com/photo-1526860918836-81de4cae5681?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNjQwNDI3MXw&ixlib=rb-4.0.3&q=80&w=1080",
  image1Alt: "Anime Title 1",
  gallery15Id: "",
  image2Alt: "Anime Title 2",
  heading1: undefined,
  image4Src:
    "https://images.unsplash.com/photo-1487954335942-047e6d1551ee?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNjQzMjgzMHw&ixlib=rb-4.0.3&q=80&w=1080",
};

Gallery15.propTypes = {
  content1: PropTypes.element,
  image1Title: PropTypes.element,
  image2Src: PropTypes.string,
  image4Alt: PropTypes.string,
  image2Title: PropTypes.element,
  image3Alt: PropTypes.string,
  image3Title: PropTypes.element,
  image1Src: PropTypes.string,
  image4Title: PropTypes.element,
  image3Src: PropTypes.string,
  image1Alt: PropTypes.string,
  gallery15Id: PropTypes.string,
  image2Alt: PropTypes.string,
  heading1: PropTypes.element,
  image4Src: PropTypes.string,
};

export default Gallery15;
