import React, { useState, useEffect, useRef } from "react";

const ImageSlider = ({ imageUrls }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalRef = useRef(null); // Store the interval ID
  const isManualInteraction = useRef(false); // Track manual interactions
  const width = window.innerWidth > 500 ? 500 : window.innerWidth - 50;
  // Change image every 3 seconds (3000ms)
  useEffect(() => {
    // Start the interval on mount
    const startInterval = () => {
      intervalRef.current = setInterval(() => {
        if (!isManualInteraction.current) {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
        }
      }, 3000);
    };

    startInterval(); // Start interval when component mounts

    // Clear interval on unmount
    return () => clearInterval(intervalRef.current);
  }, [imageUrls.length]);

  const handlePrev = () => {
    // Clear the interval when manually interacting
    clearInterval(intervalRef.current);
    isManualInteraction.current = true;
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + imageUrls.length) % imageUrls.length
    );

    // Restart the interval after 3 seconds
    setTimeout(() => {
      isManualInteraction.current = false;
      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
      }, 3000);
    }, 3000);
  };

  const handleNext = () => {
    // Clear the interval when manually interacting
    clearInterval(intervalRef.current);
    isManualInteraction.current = true;
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);

    // Restart the interval after 3 seconds
    setTimeout(() => {
      isManualInteraction.current = false;
      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
      }, 2000);
    }, 2000);
  };

  const wrapperStyle = {
    display: "flex",
    transition: "transform 1s ease-in-out", // Add transition for sliding effect
    transform: `translateX(-${currentIndex * width}px)`, // Moves the images based on current index (matching the width)
    width: `${imageUrls.length * width}px`, // Total width for all images combined
  };
  // Inline styles
  const containerStyle = {
    width: width, // Static width for the container
    height: "700px", // Static height for the container
    overflow: "hidden", // Hides anything outside the container
    position: "relative", // For positioning navigation buttons
    borderRadius: "10px", // Rounded corners for a softer look
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)", // Subtle shadow for the container
  };
  const imageStyle = {
    width: width, // Static width for each image
    height: "700px", // Static height for each image
    borderRadius: "10px", // Rounded corners for each image
    transition: "transform 0.3s ease", // Smooth hover effect
  };

  return (
    <div style={containerStyle}>
      <div style={wrapperStyle}>
        {imageUrls.map((url, index) => (
          <img
            key={index}
            alt={`Streaming Anime ${index}`}
            src={url}
            style={imageStyle}
          />
        ))}
      </div>

      {/* Navigation Arrows */}
      <button
        onClick={handlePrev}
        style={{
          position: "absolute",
          top: "50%",
          left: "10px",
          transform: "translateY(-50%)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "#fff",
          border: "none",
          borderRadius: "50%",
          padding: "10px",
          cursor: "pointer",
          fontSize: "20px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
        }}
      >
        &#10094;
      </button>
      <button
        onClick={handleNext}
        style={{
          position: "absolute",
          top: "50%",
          right: "10px",
          transform: "translateY(-50%)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "#fff",
          border: "none",
          borderRadius: "50%",
          padding: "10px",
          cursor: "pointer",
          fontSize: "20px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
        }}
      >
        &#10095;
      </button>
    </div>
  );
};

export default ImageSlider;
