import React, { Fragment, useEffect, useState } from "react";

import { Helmet } from "react-helmet";

import Gallery15 from "../components/gallery15";
import "./home.css";
import ImageSlider from "../components/imageSlider";

const Home = (props) => {
  const [version, setVersion] = useState({});
  async function fetchVersionManager() {
    try {
      const apiUrl = "https://vwatch.in/version-manager?env=prod";
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response?.json();
        setVersion(data?.data);
      }
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  useEffect(() => {
    fetchVersionManager();
  }, []);

  return (
    <div className="home-container10">
      <Helmet>
        <title>vwatch</title>
        <meta property="og:title" content="vwatch" />
      </Helmet>
      <header className="home-container11">
        <header data-thq="thq-navbar" className="home-navbar-interactive">
          <img alt="VWatch Logo" src="./favicon.png" className="home-image1" />
          <div data-thq="thq-navbar-nav" className="home-desktop-menu">
            <nav className="home-links1">
              <a href="#Home" className="home-link11 thq-link thq-body-small">
                Home
              </a>
              <a href="#Learn" className="home-link21 thq-link thq-body-small">
                Feature
              </a>
              <a
                href="#Download"
                className="home-link31 thq-link thq-body-small"
              >
                Download
              </a>
              <a
                href="#Contact"
                className="home-link41 thq-link thq-body-small"
              >
                Contact Us
              </a>
            </nav>
          </div>
          <div data-thq="thq-burger-menu" className="home-burger-menu">
            <svg viewBox="0 0 1024 1024" className="home-icon10">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-thq="thq-mobile-menu" className="home-mobile-menu">
            <div className="home-nav">
              <div className="home-top">
                <img
                  alt="VWatch Logo"
                  src="https://vwatch.in/icon"
                  className="home-logo"
                />
                <div data-thq="thq-close-menu" className="home-close-menu">
                  <svg viewBox="0 0 1024 1024" className="home-icon12">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav className="home-links2">
                <a href="#Home" className="thq-link thq-body-small">
                  Home
                </a>
                <a href="#Learn" className="thq-link thq-body-small">
                  Feature
                </a>
                <a href="#Download" className="thq-link thq-body-small">
                  Download
                </a>
                <a href="#Contact" className="thq-link thq-body-small">
                  Contact Us
                </a>
              </nav>
            </div>
            <div className="home-icon-group">
              <svg
                viewBox="0 0 950.8571428571428 1024"
                className="thq-icon-x-small"
              >
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="thq-icon-x-small"
              >
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg
                viewBox="0 0 602.2582857142856 1024"
                className="thq-icon-x-small"
              >
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </header>
      </header>
      <div className="home-container12 thq-section-padding">
        <div className="home-max-width1 thq-section-max-width">
          <div className="home-column">
            <div className="home-content1">
              <h1 className="home-text10 thq-heading-1">Welcome to vwatch!</h1>
              <p className="home-text11 thq-body-large">
                Experience anime like never before. Watch with friends, chat,
                and create unforgettable moments together.
              </p>
              <div className="home-actions1">
                <a
                  href="#Download"
                  className="home-button1 primary-color-6 dwnld-btn thq-button-filled remove-border"
                >
                  <span className="home-text12 thq-body-small">Download</span>
                </a>
                <a
                  href="#Learn"
                  className="home-button2 learn-more-btn thq-button-outline remove-border"
                >
                  <span className="home-text13 thq-body-small">
                    Learn More →
                  </span>
                </a>
              </div>
            </div>
          </div>
          {/* <iframe
            src="https://www.youtube.com/embed/Y3KmmdzKHaw?si=vgwlS59hzRFiyH2d"
            title="Vwatch Demo"
            frameborder="0"
            width="560"
            height="315"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            className="home-video thq-img-ratio-4-3"
          ></iframe> */}
        </div>
      </div>
      <div id="Learn" className="thq-section-padding">
        <div className="home-container14 thq-section-max-width">
          <ImageSlider
            imageUrls={[
              "https://vwatch.in/get-image?type=web&file=signin",
              "https://vwatch.in/get-image?type=web&file=1",
              "https://vwatch.in/get-image?type=web&file=2",
              "https://vwatch.in/get-image?type=web&file=4",
              "https://vwatch.in/get-image?type=web&file=5",
              // "https://vwatch.in/get-image?type=web&file=6",
              "https://vwatch.in/get-image?type=web&file=7",
              "https://vwatch.in/get-image?type=web&file=8",
              "https://vwatch.in/get-image?type=web&file=9",
            ]}
          />
          <div className="home-tabs-menu">
            <div className="home-tab-horizontal1">
              <div className="home-divider-container1">
                <div className="home-container15"></div>
              </div>
              <div className="home-content2">
                <h2 className="thq-heading-2">Stream Your Favorite Anime</h2>
                <span className="thq-body-small">
                  Access a vast library of anime titles for unlimited streaming.
                </span>
              </div>
            </div>
            <div className="home-tab-horizontal2">
              <div className="home-divider-container2">
                <div className="home-container16"></div>
              </div>
              <div className="home-content3">
                <h2 className="thq-heading-2">Connect with friends</h2>
                <span className="thq-body-small">
                  Track their profiles, chat with your friends, and enjoy the
                  upcoming Watch Party feature, where you can watch anime
                  together.
                </span>
              </div>
            </div>
            <div className="home-tab-horizontal3">
              <div className="home-divider-container3">
                <div className="home-container17"></div>
              </div>
              <div className="home-content4">
                <h2 className="thq-heading-2">
                  Preserve Your Anime Memories Forever
                </h2>
                <span className="thq-body-small">
                  Your finished content is saved as memories in your profile,
                  making every moment unforgettable.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Gallery15
        content1={
          <Fragment>
            <span className="home-text14 thq-body-large">
              Implies that vwatch has all the necessary tools or features the
              user is looking for.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text15 thq-heading-2">
              Everything you need in vwatch
            </span>
          </Fragment>
        }
        image1Src="/the%20mobile%20screen-900w.webp"
        image2Src="https://vwatch.in/get-image?type=web&file=1"
        image3Src="https://vwatch.in/get-image?type=web&file=2"
        image4Src="https://vwatch.in/get-image?type=web&file=5"
        gallery15Id="Features"
        image1Title={
          <Fragment>
            <span className="home-text16 thq-body-small">
              Our app makes it easy to stay connected with fellow anime lovers.
              Add friends, search by their name or username, and keep track of
              your last interactions. Enjoy watching shows together, chat in
              real-time, and build a community with like-minded fans. Make every
              viewing experience more social and engaging with our seamless
              friend management feature.
            </span>
          </Fragment>
        }
        image2Title={
          <Fragment>
            <span className="home-text17 thq-body-small">Default value</span>
          </Fragment>
        }
        image3Title={
          <Fragment>
            <span className="home-text18 thq-body-small">Default value</span>
          </Fragment>
        }
        image4Title={
          <Fragment>
            <span className="home-text19 thq-body-small">Image 4</span>
          </Fragment>
        }
      ></Gallery15> */}
      <div className="home-container18 thq-section-padding">
        <div className="home-max-width2 thq-section-max-width">
          <div className="home-container19 thq-grid-2">
            <div className="home-section-header">
              <h2 className="thq-heading-2">Steps to Join vwatch</h2>
              <p className="thq-body-large">
                Download the app, explore our vast library, and start watching
                with friends in real-time. Enjoy synchronized viewing and live
                chat for a shared experience.
              </p>
              <div className="home-actions2">
                <a
                  href="#Download"
                  className="home-button3 learn-more-btn thq-button-outline remove-border"
                >
                  <span className="home-text22 thq-body-small">
                    Go To Download →
                  </span>
                </a>
              </div>
            </div>
            <div className="home-container20">
              <div className="home-container21 thq-card">
                <h2 className="thq-heading-2 margin-top-10">
                  Download the vwatch app
                </h2>
                <span className="home-text24 thq-body-small">
                  Click on the download button below to get the vwatch app on
                  your Android device.
                </span>
                <label className="home-text25 thq-heading-3">01</label>
              </div>
              <div className="home-container22 thq-card">
                <h2 className="thq-heading-2 margin-top-10 home-text26">
                  Sign In with Google
                </h2>
                <span className="home-text27 thq-body-small">
                  Use your Google account to sign in and set up your profile.
                </span>
                <label className="home-text28 thq-heading-3">02</label>
              </div>
              <div className="home-container24 thq-card">
                <h2 className="thq-heading-2 margin-top-10">
                  Dive into Anime with vwatch
                </h2>
                <span className="thq-body-small home-text33">
                  Experience the ultimate anime adventure with vwatch! Enjoy a
                  seamless, immersive platform where your finished content is
                  saved as cherished memories in your profile forever.
                </span>
                <label className="home-text34 thq-heading-3">03</label>
              </div>
              <div className="home-container23 thq-card">
                <h2 className="thq-heading-2 margin-top-10">
                  Connect and Share
                </h2>
                <span className="thq-body-small home-text30">
                  Make new friends and track their profiles.
                </span>
                <span className="thq-body-small home-text30">
                  Stay tuned for the Watch Party feature, coming soon, where you
                  can enjoy anime together with friends!
                </span>

                <label className="home-text31 thq-heading-3">04</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="Download" className="thq-section-padding">
        <div className="thq-section-max-width">
          <div className="home-accent2-bg1">
            <div className="home-accent1-bg1">
              <div className="home-container26">
                <div className="home-content5">
                  <span className="thq-heading-2">
                    Watch Your Favorite Anime Anytime, Anywhere
                  </span>
                  <p className="thq-body-large">
                    Download vwatch now and immerse yourself in a world of anime
                    entertainment.
                  </p>
                </div>
                <div className="home-actions3">
                  <a
                    href={version.buildUrl}
                    className="home-button4 primary-color-6 dwnld-btn thq-button-filled remove-border"
                  >
                    <span className="home-text37 thq-body-small">
                       Download Now ↓
                    </span>
                  </a>
                </div>
                <span className="thq-heading-2" style={{ fontSize: "13px" }}>
                  Build Version : {version.otaVersion}
                  <br />
                  Last Updated At : {version.lastUpdatedAt}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="thq-section-padding">
        <div className="home-max-width4 thq-section-max-width">
          <div className="home-container27">
            <h2 className="thq-heading-2">What Our Users Say</h2>
            <span className="thq-body-small">
              vwatch has made my anime streaming experience so much better! The
              social features are amazing, and I love creating watch rooms with
              my friends.
            </span>
          </div>
          <div className="thq-grid-2">
            <div className="thq-animated-card-bg-2">
              <div className="thq-animated-card-bg-1">
                <div data-animated="true" className="thq-card home-card1">
                  <div className="home-container29">
                    <img
                      alt="Image of Sakura"
                      src="https://images.unsplash.com/photo-1458696352784-ffe1f47c2edc?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNjMyMTU4OHw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=200"
                      className="home-image3"
                    />
                    <div className="home-container30">
                      <strong className="thq-body-large">Reetu</strong>
                      <span className="thq-body-small">Anime Enthusiast</span>
                    </div>
                  </div>
                  <span className="thq-body-small">
                    vwatch is perfect for anime fans. The live chat feature
                    makes watching shows more fun by letting you chat with
                    others while you watch.
                  </span>
                </div>
              </div>
            </div>
            <div className="thq-animated-card-bg-2">
              <div className="thq-animated-card-bg-1">
                <div data-animated="true" className="thq-card home-card2">
                  <div className="home-container31">
                    <img
                      alt="Image of Yuki"
                      src="https://images.unsplash.com/photo-1524373079361-ff1452de1d84?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNjMyMTU4OXw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=200"
                      className="home-image4"
                    />
                    <div className="home-container32">
                      <strong className="thq-body-large">Ayush</strong>
                      <span className="thq-body-small">Manga Artist</span>
                    </div>
                  </div>
                  <span className="thq-body-small">
                    vwatch is a must-have for any anime lover. The live chat
                    feature adds a whole new level of excitement to watching
                    shows together.
                  </span>
                </div>
              </div>
            </div>
            <div className="thq-animated-card-bg-2">
              <div className="thq-animated-card-bg-1">
                <div data-animated="true" className="thq-card home-card3">
                  <div className="home-container33">
                    <img
                      alt="Image of Hiro"
                      src="https://images.unsplash.com/photo-1519699047748-de8e457a634e?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNjMyMTU4OXw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=200"
                      className="home-image5"
                    />
                    <div className="home-container34">
                      <strong className="thq-body-large">Ankit</strong>
                      <span className="thq-body-small">Cosplayer</span>
                    </div>
                  </div>
                  <span className="home-text48 thq-body-small">
                    Highly recommend vwatch to all anime fans out there!
                    It&apos;s like having a virtual anime club in your pocket.
                  </span>
                </div>
              </div>
            </div>
            <div className="thq-animated-card-bg-2">
              <div className="thq-animated-card-bg-1">
                <div data-animated="true" className="thq-card home-card4">
                  <div className="home-container35">
                    <img
                      alt="Image of Kenji"
                      src="https://images.unsplash.com/photo-1715810349661-f4ee28d06228?crop=entropy&amp;cs=tinysrgb&amp;fit=max&amp;fm=jpg&amp;ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNjMyMTU4OHw&amp;ixlib=rb-4.0.3&amp;q=80&amp;w=200"
                      className="home-image6"
                    />
                    <div className="home-container36">
                      <strong className="thq-body-large">Laxmikant</strong>
                      <span className="thq-body-small">Gamer</span>
                    </div>
                  </div>
                  <span className="thq-body-small">
                    vwatch has become my go-to app for streaming anime. The
                    interface is sleek, and the watch rooms feature is perfect
                    for hosting viewing parties.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div id="Contact" className="home-container37 thq-section-padding">
        <div className="home-max-width5 thq-section-max-width">
          <div className="home-content6 thq-flex-row">
            <div className="home-content7">
              <h2 className="thq-heading-2">Contact Us</h2>
              <p className="thq-body-large">
                Have a question or feedback? Feel free to reach out to us!
              </p>
            </div>
          </div>
          <div className="home-content8 thq-flex-row">
            <div className="home-container38">
              <img
                alt="Email Icon"
                src="/getimg_ai_img-kk8yaj38oohctuzgxiqsh-1400w.jpeg"
                className="home-image7 thq-img-ratio-16-9"
              />
              <p className="home-text55 thq-body-large">
                <span>For any kind of support please feel free to</span>
                <br></br>
                <span>
                  Drop us an email and we&apos;ll get back to you as soon as
                  possible.
                </span>
              </p>
              <div className="home-container39">
                <a
                  href="mailto:vwatchhelp@gmail.com?subject=Query"
                  className="home-button5 learn-more-btn thq-button-outline remove-border"
                >
                  <span className="home-text59 thq-body-small">
                    Send Email →
                  </span>
                </a>
              </div>
            </div>
            <div className="home-container40">
              <img
                alt="Phone Icon"
                src="/join-our-telegram-sa-crypto-1400w.jpg"
                className="home-image8 thq-img-ratio-16-9"
              />
              <p className="home-text61 thq-body-large">
                Join us on Telegram to engage in discussions and help shape the
                future of our product according to your preferences.
              </p>
              <div className="home-container41">
                <a
                  href="https://t.me/theVwatch"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-button6 learn-more-btn thq-button-outline remove-border"
                >
                  <span className="home-text62 thq-body-small">
                    Join Channel →
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="home-footer7 thq-section-padding">
        <div className="home-max-width6 thq-section-max-width">
          <div className="home-credits">
            <div className="thq-divider-horizontal"></div>
            <div className="home-row">
              <div className="home-container42">
                <span className="thq-body-small">© 2024 vwatch</span>
              </div>
              <div className="home-footer-links">
                <a
                  href="/privacy_policy"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <span className="home-text64 thq-body-small">
                    Privacy Policy
                  </span>
                </a>
                <a
                  href="/terms_of_service"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <span className="thq-body-small">Terms of Use</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
